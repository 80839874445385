import 'mdb-vue-ui-kit/css/mdb.min.css'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index';
import store from './store/store';
import Chat from 'vue3-beautiful-chat'
import IdleVue from "idle-vue";
import VuePdf from 'vue3-pdfjs'
import VueGtag from "vue-gtag-next";
import { trackRouter } from "vue-gtag-next";

const app = createApp(App);
app.use(router);
app.use(store);
app.use(Chat);
app.use(IdleVue, {
    store,
    idleTime: 300000000,
    startAtIdle: false
});
app.use(VuePdf);
app.use(VueGtag, {
    property: {
      id: "G-JMHL52YNQR"
    }
});
trackRouter(router);

app.mount('#app');