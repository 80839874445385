import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
      path: '/world',
      name: 'WorldView',
      component: () => import('@/layout/WorldPage'),
      children: [
        {
            path: '/world/lobby',
            name: 'lobbyView',
            component: () => import('@/views/sceneViews/lobbyView.vue'),
        },
        {
            path: '/world/gallery',
            name: 'galleryView',
            component: () => import('@/views/sceneViews/galleryView.vue'),
        },
        {
          path: '/world/lobby/multi',
          name: 'lobbyMultiView',
          component: () => import('@/views/sceneViews/lobbyMultiView.vue'),
        },
        {
          path: '/world/gallery/multi',
          name: 'galleryMultiView',
          component: () => import('@/views/sceneViews/galleryMultiView.vue'),
        },
        {
          path: '/world/conference',
          name: 'conferencehallView',
          component: () => import('@/views/sceneViews/conferenceView.vue'),
        },
        {
          path: '/world/conference/multi',
          name: 'conferenceMultiView',
          component: () => import('@/views/sceneViews/conferenceMultiView.vue'),
        },
        {
          path: '/world/event',
          name: 'eventView',
          component: () => import('@/views/sceneViews/eventView.vue'),
        },
      ]
    },
    {
      path: '/',
      name: 'MainView',
      component: () => import("@/layout/IntroPage.vue"),
      children: [
        {
          path: "/",
          name: "IntroView",
          component: () => import("@/views/sceneViews/introView.vue")
        }
      ]
    },
    {
      path: "/error",
      name: "ErrorView",
      component: () => import("@/layout/ErrorPage.vue"),
      children: [
        {
          path: "/error/expired",
          name: "SessionExpView",
          component: () => import("@/views/error/SessionExpiredView.vue")
        },
        {
          path: "/error/exceedscapacity",
          name: "ExceedsCapacityView",
          component: () => import("@/views/error/ExceedsCapacityView.vue")
        }
      ]
    },
    {
      path: '/test',
      name: 'Testiew',
      component: () => import("@/views/TestForRelease"),
    },
    {
      path: '/test-conference',
      name: 'TestConference',
      component: () => import("@/views/ConferenceTest"),
    },
    {
      path: "/character",
      name: "CharacterView",
      component: () => import("@/views/sceneViews/CharacterView.vue")
    },
    {
      path: "/share/:userId",
      name: "QuestCompleteView",
      component: () => import("@/views/sceneViews/QuestCompleteView.vue")
    },
];

const router = createRouter({
    history: createWebHistory("/metaverse/"),
    base: "/metaverse/",
    routes
  })
  
export default router